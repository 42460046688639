<template>
  <v-container>
    <v-chip-group
      v-model="selectedComponent"
      @change="onChange"
      active-class="primary"
      column
      mandatory
      class="mb-2"
    >
      <v-chip
        v-for="component in components"
        :key="component.componentId"
        :value="component"
        x-small
        label
      >
        {{ component.jsName }}
      </v-chip>
    </v-chip-group>
    <span v-if="showColumnLayout">
      <v-checkbox
        v-model="columnLayout"
        @change="onChange"
        label="Column layout"
        small
        dense
        hide-details
      ></v-checkbox>
    </span>
  </v-container>
</template>
<script>
import { FieldTypes } from "../../objs/Shared/FieldTypes.js";
export default {
  props: ["question"],
  data() {
    return {
      selectedComponent: this.question?.component || {},
      columnLayout: this.question?.columnLayout,
    };
  },
  computed: {
    components: function () {
      return this.$store.state.optionsModule.components;
    },
    showColumnLayout: function () {
      return [FieldTypes.CHIP_SELECT, FieldTypes.MULTIPLE_CHIP_SELECT].includes(
        this.selectedComponent?.jsName
      );
    },
  },
  methods: {
    onChange: function () {
      this.$emit("componentChange", {
        component: this.selectedComponent,
        ...(this.showColumnLayout && { columnLayout: this.columnLayout }),
      });
    },
  },
};
</script>
