<template>
  <validation-provider rules="required" v-slot="{ errors }">
    <v-autocomplete
      v-model="comparator"
      @change="onChange"
      :items="comparatorOptions"
      :error-messages="errors"
      outlined
      background-color="#FFFFFF"
    ></v-autocomplete>
  </validation-provider>
</template>
<script>
import { Comparators } from "../../objs/Comparators";
import { FieldTypes } from "../../objs/Shared/FieldTypes";

export default {
  props: ["parentComponentName", "initialComparator"],
  data() {
    return {
      comparator: this.initialComparator || "",
    };
  },
  computed: {
    comparatorOptions: function () {
      switch (this.parentComponentName) {
        case FieldTypes.TEXT_FIELD:
        case FieldTypes.TEXT_AREA:
        case FieldTypes.PHONE_NUMBER_FIELD:
        case FieldTypes.ZIP_CODE_FIELD:
          // string comparators
          return [
            Comparators.EQUALS,
            Comparators.IS_EMPTY,
            Comparators.IS_NOT_EMPTY,
          ];
        case FieldTypes.NUMERIC_FIELD:
        case FieldTypes.DATE_PICKER:
        case FieldTypes.TIME_INPUT:
          // numeric comparators
          return [
            Comparators.EQUALS,
            Comparators.GREATER,
            Comparators.LESS,
            Comparators.GREATER_OR_EQUAL,
            Comparators.LESS_OR_EQUAL,
            Comparators.BETWEEN,
            Comparators.IS_EMPTY,
            Comparators.IS_NOT_EMPTY,
          ];
        case FieldTypes.CHECKBOX:
          // boolean comparators
          return [
            Comparators.EQUALS,
            Comparators.IS_EMPTY,
            Comparators.IS_NOT_EMPTY,
          ];
        case FieldTypes.RADIO_GROUP:
        case FieldTypes.STANDARD_SELECT:
        case FieldTypes.AUTOCOMPLETE:
        case FieldTypes.CHIP_SELECT:
          // select comparators
          return [
            Comparators.CONTAINS_ANY,
            Comparators.IS_EMPTY,
            Comparators.IS_NOT_EMPTY,
          ];
        case FieldTypes.MULTIPLE_SELECT:
        case FieldTypes.MULTIPLE_CHIP_SELECT:
          // multiple select comparators
          return [
            Comparators.CONTAINS_ANY,
            Comparators.MATCHES_ALL_ONLY,
            Comparators.CONTAINS_ALL,
            Comparators.IS_EMPTY,
            Comparators.IS_NOT_EMPTY,
          ];
      }
      return [];
    },
  },
  methods: {
    onChange: function (event) {
      this.$emit("comparatorChange", event);
    },
  },
};
</script>
