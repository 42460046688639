import { extend } from "vee-validate";
import {
  required,
  min,
  max,
  min_value,
  max_value,
  alpha,
  double,
  numeric,
  alpha_num,
  email,
} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("requiredTrue", (value) => {
  if (value === true) {
    return true;
  }

  return "This field is required";
});

extend("minLength", {
  params: ["length"],
  validate: (value, { length }) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  },
  message: "This field requires minimum length {length}",
});

extend("maxLength", {
  params: ["length"],
  validate: (value, { length }) => {
    if (value.length <= length) {
      return true;
    }
    return false;
  },
  message: "This field exceeds maximum length {length}",
});

extend("minValue", {
  ...min_value,
  params: ["min"],
  message: "This field requires minimum value {min}",
});

extend("maxValue", {
  ...max_value,
  params: ["max"],
  message: "This field exceeds maximum value {max}",
});

extend("alpha", {
  ...alpha,
  message: "This field must only contain alphabet characters",
});

extend("numeric", {
  ...numeric,
  message: "This field must only contain numeric characters",
});

extend("double", {
  ...double,
  message: "This field must only contain numeric characters",
});

extend("alphaNum", {
  ...alpha_num,
  message: "This field must only contain letters or numbers",
});

extend("email", {
  ...email,
  message: "This field must be a valid email",
});

extend("alphaExtra", (value) => {
  if (value === "" || /^[a-zA-Z-. \s]+$/g.test(value)) {
    return true;
  }

  return "This field must only contain letters, dashes, or periods";
});

extend("alphaNumExtra", (value) => {
  if (value === "" || /^[a-zA-Z0-9-. \s]+$/g.test(value)) {
    return true;
  }

  return "This field must only contain letters, numbers, dashes, or periods";
});

extend("phoneNumber", (value) => {
  if (
    value === "" ||
    /(\([0-9][0-9][0-9]\)\s[0-9][0-9][0-9]\-[0-9][0-9][0-9][0-9])/.test(value)
  ) {
    return true;
  }

  return "This field must be a valid phone number";
});

extend("ValidFeedbackEmail", (value) => {
  if (value.includes("@") && !value.includes("omniuserportal")) {
    return true;
  }

  return "Please use your personal email address for this communication";
});

extend("DistinctResponse", {
  params: ["target"],
  validate(value, { target }) {
    return !target.includes(value);
  },
  message: "This field requires a distinct response",
});
