<template>
  <v-card height="84vh">
    <v-card-title
      class="text-decoration-underline"
      color="primary"
      style="height: 10%"
      >Review your responses:</v-card-title
    >
    <v-card-text style="height: 80%; overflow: auto">
      <v-list v-for="family in renderedFamilies" :key="family.familyId" dense>
        <v-subheader>{{ family.label }}</v-subheader>
        <v-list-item
          v-for="(question, index) in getRenderedQuestions(family.familyId)"
          :key="question.questionId"
        >
          <v-list-item-icon class="mr-2">
            <label :class="highlightMissingResponse(question)"
              >{{ index + 1 }})</label
            >
          </v-list-item-icon>
          <v-list-item-content>
            <label
              :class="highlightMissingResponse(question)"
              v-sanitize-html="question.label"
            ></label>
          </v-list-item-content>
          <v-list-item-content class="ml-3">
            <label
              class="font-weight-bold"
              v-sanitize-html="getResponseToDisplay(question)"
            ></label>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions style="height: 10%">
      <v-btn color="primary" text rounded small @click="back"
        ><icon icon="arrow-left" small class="mr-2"></icon>Back</v-btn
      >
      <dashboard-action></dashboard-action>
      <v-spacer></v-spacer>
      <span v-if="isMissingResponses" class="text-caption error--text mx-2"
        >* Please go back to review the missing responses</span
      >
      <v-btn
        color="primary"
        :dark="!isMissingResponses"
        :disabled="isMissingResponses"
        rounded
        small
        @click="next"
        >Compose Narrative</v-btn
      >
      <confirm-modal ref="confirm"></confirm-modal>
    </v-card-actions>
  </v-card>
</template>
<script>
import ConfirmModal from "../Shared/confirm-modal.vue";
import {
  formatLongDate,
  formatTime,
  getDateFromDisplayTime,
  formatMandateResponse,
} from "../../utils/format-utils";
import { FieldTypes } from "../../objs/Shared/FieldTypes";
import DashboardAction from "./dashboard-action.vue";
export default {
  components: {
    ConfirmModal,
    DashboardAction,
  },
  computed: {
    renderedFamilies: function () {
      return this.$store.getters["notesModule/getRenderedFamilies"];
    },
    templateNote: function () {
      return this.$store.state.notesModule.selectedTemplateNote;
    },
    isMissingResponses() {
      return this.$store.getters["notesModule/isMissingResponses"] === true;
    },
  },
  methods: {
    getRenderedQuestions(familyId) {
      return this.$store.getters["notesModule/getRenderedQuestionsByFamily"](
        familyId
      );
    },
    highlightMissingResponse(question) {
      return this.$store.getters["notesModule/isQuestionMissingResponse"](
        question.questionId
      ) === true
        ? "error--text"
        : "";
    },
    getResponseToDisplay: function (question) {
      let response = this.$store.getters[
        "notesModule/getQuestionResponseValue"
      ](question.questionId);

      if (response == null) return "";

      switch (question.componentName) {
        case FieldTypes.TEXT_FIELD:
        case FieldTypes.TEXT_AREA:
        case FieldTypes.NUMERIC_FIELD:
        case FieldTypes.PHONE_NUMBER_FIELD:
        case FieldTypes.ZIP_CODE_FIELD:
          return response.toString();
        case FieldTypes.DATE_PICKER:
          return formatLongDate(response);
        case FieldTypes.TIME_INPUT:
          return formatTime(getDateFromDisplayTime(response));
        case FieldTypes.CHECKBOX:
          return response == true ? "Yes" : "No";
        case FieldTypes.STANDARD_SELECT:
        case FieldTypes.AUTOCOMPLETE:
        case FieldTypes.RADIO_GROUP:
        case FieldTypes.MULTIPLE_SELECT:
        case FieldTypes.CHIP_SELECT:
        case FieldTypes.MULTIPLE_CHIP_SELECT:
          return response.map((item) => item.option).join(", ");
        case FieldTypes.SIGNATURE:
          return `<img class="image" src=${response}>`;
        default:
          return response;
      }
    },
    back: function () {
      this.$router.push({
        name: "TemplateNote",
        query: {
          pos: this.renderedFamilies.length - 1,
        },
      });
    },
    next: async function () {
      // only show modal if note text was previously saved
      if (
        !this.templateNote.noteText ||
        (await this.$refs.confirm.open(
          "Re-Compose Narrative",
          "Note: This will undo any previous changes to the narrative",
          "Compose Narrative"
        ))
      ) {
        this.$store.commit("uxModule/setShowLoader", true);
        this.$store.commit("uxModule/setLoaderMsg", "Composing Narrative...");
        await this.$store.dispatch("notesModule/composeNarrative");
        this.$store.commit("uxModule/setShowLoader", false);

        this.$router.push({ name: "Narrative" });
      }
    },
  },
};
</script>
