import store from "../store";
import Component from "./Component";
import QuestionOption from "./QuestionOption";
import Rendering from "./Rendering";
import { Validation } from "./Validation";

export default class Question {
  questionId?: number;
  familyId?: number;
  componentId?: number;
  component: Component | null;
  columnLayout: Boolean;
  sequence?: number | null;
  label?: string | null;
  options?: QuestionOption[];
  renderingId?: number;
  rendering?: Rendering | null;

  // validation rules
  validationRequired?: boolean;
  validationRequiredTrue?: boolean;
  validationMinLength?: boolean;
  validationMaxLength?: boolean;
  validationMinValue?: boolean;
  validationMaxValue?: boolean;
  validationAlpha?: boolean;
  validationAlphaNumeric?: boolean;
  validationNumeric?: boolean;
  validationEmail?: boolean;
  validationPhoneNumber?: boolean;
  validationDistinct?: boolean;
  validationMin?: any | null;
  validationMax?: any | null;
  validationMinDate?: Date | null;
  validationMaxDate?: Date | null;

  public constructor(params: Question = {} as Question) {
    let {
      questionId = 0,
      familyId = 0,
      componentId = 0,
      component = new Component(),
      columnLayout = false,
      sequence = 0,
      label = "",
      options = new Array<QuestionOption>(),
      renderingId = 0,
      rendering = new Rendering(),
      validationRequired = false,
      validationRequiredTrue = false,
      validationMinLength = false,
      validationMaxLength = false,
      validationMinValue = false,
      validationMaxValue = false,
      validationAlpha = false,
      validationAlphaNumeric = false,
      validationNumeric = false,
      validationEmail = false,
      validationPhoneNumber = false,
      validationDistinct = false,
      validationMin = 0,
      validationMax = 0,
      validationMinDate = null,
      validationMaxDate = null,
    } = params;

    this.questionId = questionId;
    this.familyId = familyId;
    this.componentId = componentId;
    this.component = component && new Component(component);
    this.columnLayout = columnLayout;
    this.sequence = sequence;
    this.label = label;
    this.options = options && options.map((o) => new QuestionOption(o));
    this.renderingId = renderingId;
    this.rendering = rendering && new Rendering(rendering);
    this.validationRequired = validationRequired;
    this.validationRequiredTrue = validationRequiredTrue;
    this.validationMinLength = validationMinLength;
    this.validationMaxLength = validationMaxLength;
    this.validationMinValue = validationMinValue;
    this.validationMaxValue = validationMaxValue;
    this.validationAlpha = validationAlpha;
    this.validationAlphaNumeric = validationAlphaNumeric;
    this.validationNumeric = validationNumeric;
    this.validationEmail = validationEmail;
    this.validationPhoneNumber = validationPhoneNumber;
    this.validationDistinct = validationDistinct;
    this.validationMin = validationMin;
    this.validationMax = validationMax;
    this.validationMinDate = validationMinDate;
    this.validationMaxDate = validationMaxDate;
  }

  get family() {
    return (
      store.getters["templatesModule/getFamily"](this.familyId) ||
      store.getters["notesModule/getFamily"](this.familyId)
    );
  }

  get componentName() {
    let component =
      this.component ||
      store.getters["optionsModule/component"](this.componentId);

    return component?.jsName;
  }

  get validations() {
    let arr = Array<Validation>();
    if (this.validationRequired) arr.push(Validation.Required);
    if (this.validationRequiredTrue) arr.push(Validation.RequiredTrue);
    if (this.validationMinLength) arr.push(Validation.MinLength);
    if (this.validationMaxLength) arr.push(Validation.MaxLength);
    if (this.validationMinValue) arr.push(Validation.MinValue);
    if (this.validationMaxValue) arr.push(Validation.MaxValue);
    if (this.validationAlpha) arr.push(Validation.AlphaExtra);
    if (this.validationAlphaNumeric) arr.push(Validation.AlphaNumExtra);
    if (this.validationNumeric) arr.push(Validation.Numeric);
    if (this.validationEmail) arr.push(Validation.Email);
    if (this.validationPhoneNumber) arr.push(Validation.PhoneNumber);
    if (this.validationDistinct) arr.push(Validation.Distinct);

    return arr;
  }

  static mapValidations(
    arr: Array<Validation>,
    minNum: number,
    maxNum: number
  ): any {
    let obj: any = {};
    if (arr && arr.length > 0) {
      if (arr.includes(Validation.Required)) obj.validationRequired = true;
      if (arr.includes(Validation.RequiredTrue))
        obj.validationRequiredTrue = true;
      if (arr.includes(Validation.MinLength)) {
        obj.validationMinLength = true;
        obj.validationMin = minNum;
      }
      if (arr.includes(Validation.MaxLength)) {
        obj.validationMaxLength = true;
        obj.validationMax = maxNum;
      }
      if (arr.includes(Validation.MinValue)) {
        obj.validationMinValue = true;
        obj.validationMin = minNum;
      }
      if (arr.includes(Validation.MaxValue)) {
        obj.validationMaxValue = true;
        obj.validationMax = maxNum;
      }
      if (arr.includes(Validation.AlphaExtra)) obj.validationAlpha = true;
      if (arr.includes(Validation.AlphaNumExtra))
        obj.validationAlphaNumeric = true;
      if (arr.includes(Validation.Numeric)) obj.validationNumeric = true;
      if (arr.includes(Validation.Email)) obj.validationEmail = true;
      if (arr.includes(Validation.PhoneNumber))
        obj.validationPhoneNumber = true;
      if (arr.includes(Validation.Distinct)) obj.validationDistinct = true;
    }
    return obj;
  }
}
