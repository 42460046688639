<template>
  <span>
    <template v-if="betweenComparator">
      <v-row>
        <v-col cols="4">
          <form-field
            :field="betweenMinConditionField"
            :validations="validations"
            @fieldChange="minConditionFieldChange"
          ></form-field>
        </v-col>
        <v-col cols="4">
          <form-field
            :field="betweenMaxConditionField"
            :validations="validations"
            @fieldChange="maxConditionFieldChange"
          ></form-field>
        </v-col>
      </v-row>
    </template>
    <form-field
      v-else-if="optionsComparator"
      :field="optionsConditionField"
      :validations="validations"
      @fieldChange="optionsFieldChange"
    ></form-field>
    <form-field
      v-else-if="singleComparator"
      :field="conditionField"
      :validations="validations"
      @fieldChange="fieldChange"
    ></form-field>
  </span>
</template>
<script>
import { Comparators } from "../../objs/Comparators";
import { FieldTypes } from "../../objs/Shared/FieldTypes";
import FormField from "../../objs/Shared/FormField";
import { required } from "../../plugins/vee-validate/validation";

export default {
  props: [
    "parentComponentName",
    "parentOptions",
    "comparator",
    "initialConditions",
  ],
  data() {
    return {
      renderValue: this.initialConditions?.renderValue,
      renderValueMin: this.initialConditions?.renderValueMin,
      renderValueMax: this.initialConditions?.renderValueMax,
      renderValueOptions: this.initialConditions?.renderValueOptions || [],
      validations: { required: true },
    };
  },
  computed: {
    betweenComparator: function () {
      return Comparators.BETWEEN === this.comparator;
    },
    optionsComparator: function () {
      return [
        Comparators.CONTAINS_ANY,
        Comparators.MATCHES_ALL_ONLY,
        Comparators.CONTAINS_ALL,
      ].includes(this.comparator);
    },
    singleComparator: function () {
      return (
        !this.betweenComparator &&
        !this.optionsComparator &&
        !(Comparators.IS_EMPTY === this.comparator) &&
        !(Comparators.IS_NOT_EMPTY === this.comparator)
      );
    },
    conditionLabel: function () {
      switch (this.parentComponentName) {
        case FieldTypes.TEXT_FIELD:
        case FieldTypes.TEXT_AREA:
        case FieldTypes.PHONE_NUMBER_FIELD:
        case FieldTypes.ZIP_CODE_FIELD:
        case FieldTypes.NUMERIC_FIELD:
        case FieldTypes.DATE_PICKER:
        case FieldTypes.TIME_INPUT:
          // string or numeric label
          return "Enter the value(s)";
        case FieldTypes.CHECKBOX:
          // boolean label
          return "Check to match true condition";
        case FieldTypes.RADIO_GROUP:
        case FieldTypes.STANDARD_SELECT:
        case FieldTypes.AUTOCOMPLETE:
        case FieldTypes.MULTIPLE_SELECT:
        case FieldTypes.CHIP_SELECT:
        case FieldTypes.MULTIPLE_CHIP_SELECT:
          // options label
          return "Select the option(s)";
      }
      return "Enter the value(s) or select the option(s)";
    },
    conditionField: function () {
      return new FormField({
        value: this.renderValue,
        componentName: this.parentComponentName,
        outlined: true,
      });
    },
    optionsConditionField: function () {
      return new FormField({
        value: this.renderValueOptions,
        componentName: FieldTypes.MULTIPLE_SELECT,
        options: this.parentOptions,
        itemText: "option",
        comparatorValue: "questionOptionId",
        outlined: true,
      });
    },
    betweenMinConditionField: function () {
      return new FormField({
        label: "Enter the minimum value",
        value: this.renderValueMin,
        componentName: this.parentComponentName,
        outlined: true,
      });
    },
    betweenMaxConditionField: function () {
      return new FormField({
        label: "Enter the maximum value",
        value: this.renderValueMax,
        componentName: this.parentComponentName,
        outlined: true,
      });
    },
  },
  methods: {
    conditionChange: function () {
      this.$emit("conditionChange", {
        ...(this.singleComparator && { renderValue: this.renderValue }),
        ...(this.betweenComparator && { renderValueMin: this.renderValueMin }),
        ...(this.betweenComparator && { renderValueMax: this.renderValueMax }),
        ...(this.optionsComparator && {
          renderValueOptions: this.renderValueOptions,
        }),
      });
    },
    fieldChange: function (event) {
      this.renderValue = event;
      this.conditionChange();
    },
    optionsFieldChange: function (event) {
      this.renderValueOptions = event;
      this.conditionChange();
    },
    minConditionFieldChange: function (event) {
      this.renderValueMin = event;
      this.conditionChange();
    },
    maxConditionFieldChange: function (event) {
      this.renderValueMax = event;
      this.conditionChange();
    },
  },
};
</script>
