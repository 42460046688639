import * as api from "../Services/api.js";
import ReportAssignmentDetails from "../../objs/ReportAssignmentDetails";
import ReportAssignment from "../../objs/ReportAssignment";
import Note from "../../objs/ReportAssignmentNote";
import Mandate from "../../objs/Mandate";
import Assignment from "../../objs/Assignment";
import ReportAssignmentFileDetails from "../../objs/ReportAssignmentFileDetails";
import { ReportAssignmentStatus } from "../../objs/ReportAssignmentStatus";

export const state = {
  reportAssignments: [], // ReportAssignmentDetails model with subset fields
  selectedReportAssignment: null, // ReportAssignment model with all fields
  filters: {
    providerFilter: "",
    clientFilter: "",
    teamFilter: [],
    eiStaffingTeamFilter: "",
    serviceFilter: [],
    reportTypeFilter: "",
    statusFilter: [],
    dueDateFilter: [],
    flagFilter: "",
    currentPlansFilter: true,
  },
  programView: "", // "SEIT" or "EIO"
};

export const mutations = {
  SET_REPORT_ASSIGNMENTS(state, payload) {
    state.reportAssignments = payload
      ? payload.map((item) => new ReportAssignmentDetails(item))
      : [];
  },
  ADD_REPORT_ASSIGNMENT(state, payload) {
    state.reportAssignments.push(new ReportAssignmentDetails(payload));
  },
  UPDATE_REPORT_ASSIGNMENT(state, payload) {
    state.reportAssignments = state.reportAssignments.map((item) => {
      return item.reportAssignmentId === payload.reportAssignmentId
        ? new ReportAssignmentDetails(payload)
        : item;
    });
  },
  DELETE_REPORT_ASSIGNMENT(state, reportAssignmentId) {
    state.reportAssignments = state.reportAssignments.filter(
      (item) => item.reportAssignmentId !== reportAssignmentId
    );
  },
  SET_SELECTED_REPORT_ASSIGNMENT(state, payload) {
    state.selectedReportAssignment = payload
      ? new ReportAssignment(payload)
      : null;
  },
  ADD_NOTE(state, payload) {
    state.selectedReportAssignment.notes.push(new Note(payload));
  },
  UPDATE_NOTE(state, payload) {
    state.selectedReportAssignment.notes =
      state.selectedReportAssignment.notes.map((item) => {
        return item.reportAssignmentNoteId == payload.reportAssignmentNoteId
          ? new Note(payload)
          : item;
      });
  },
  DELETE_NOTE(state, noteId) {
    state.selectedReportAssignment.notes =
      state.selectedReportAssignment.notes.filter(
        (item) => item.reportAssignmentNoteId != noteId
      );
  },
  SET_FILTERS(state, payload) {
    state.filters[payload.filterName] = payload.filterValue;
  },
  CLEAR_FILTERS(state) {
    state.filters = {
      providerFilter: "",
      clientFilter: "",
      teamFilter: [],
      eiStaffingTeamFilter: "",
      serviceFilter: [],
      reportTypeFilter: "",
      statusFilter: [],
      dueDateFilter: [],
      flagFilter: "",
      currentPlansFilter: true,
    };
  },
  TOGGLE_PROGRAM_VIEW(state, payload) {
    state.programView = payload;
  },
};

export const getters = {
  getReportAssignment: (state) => (reportAssignmentId) => {
    return state.reportAssignments.find(
      (item) => item.reportAssignmentId === reportAssignmentId
    );
  },
  getReportAssignmentsForClient: (state) => (clientId) => {
    return state.reportAssignments.filter((item) => item.clientId === clientId);
  },
  getStatusDisplay: () => (status) => {
    switch (status) {
      case ReportAssignmentStatus.CANCELLED:
        return { icon: "mdi-close", color: "#808080" };
      case ReportAssignmentStatus.SUBMITTED_TO_BOE:
        return { icon: "mdi-toggle-switch", color: "primary" };
      case ReportAssignmentStatus.ACCEPTED:
        return { icon: "mdi-check-all", color: "success" };
      case ReportAssignmentStatus.REJECTED:
        return { icon: "mdi-exclamation", color: "warning" };
      case ReportAssignmentStatus.READY_FOR_REVIEW:
        return { icon: "mdi-check", color: "secondary" };
      case ReportAssignmentStatus.DRAFT:
      case ReportAssignmentStatus.IN_PROGRESS:
      case ReportAssignmentStatus.AWAITING:
      default:
        return { icon: "mdi-dots-horizontal", color: "accent" };
    }
  },
};

export const actions = {
  async getSEITReportAssignments({ commit }) {
    return await api
      .getQuery("/reportAssignments/getSEITReportAssignments")
      .then((response) => {
        if (response.success) {
          commit("SET_REPORT_ASSIGNMENTS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async getEIReportAssignments({ commit }) {
    return await api
      .getQuery("/reportAssignments/getEIReportAssignments")
      .then((response) => {
        if (response.success) {
          commit("SET_REPORT_ASSIGNMENTS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async getReportAssignmentsForProvider({ commit, rootState, dispatch }) {
    let providerId = rootState.sharedModule.providerId;
    if (!providerId) {
      providerId = await dispatch("sharedModule/getProviderId", null, {
        root: true,
      });
    }

    return await api
      .getQuery("/reportAssignments/getReportAssignmentsForProvider", {
        providerId: providerId,
      })
      .then((response) => {
        if (response.success) {
          commit("SET_REPORT_ASSIGNMENTS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async clearReportAssignment({ commit }) {
    commit("SET_SELECTED_REPORT_ASSIGNMENT", null);
  },
  async loadReportAssignment({ commit }, data) {
    return await api
      .getQuery("/reportAssignments/loadReportAssignment", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SELECTED_REPORT_ASSIGNMENT", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async createReportAssignment({ commit }, data) {
    return await api
      .post("/reportAssignments/createReportAssignment", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_REPORT_ASSIGNMENT", response.data);
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return false;
      });
  },
  async updateReportAssignment({ commit }, data) {
    return await api
      .post("/reportAssignments/updateReportAssignment", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_REPORT_ASSIGNMENT", response.data);
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return false;
      });
  },
  async saveNote({ commit, dispatch, rootState }, data) {
    let user = rootState.securityModule.userProfile;

    return await api
      .post("/reportAssignments/createNote", {
        ...data,
        userId: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
      })
      .then((response) => {
        if (response.success) {
          commit("ADD_NOTE", response.data);
          dispatch("loadReportAssignment", {
            reportAssignmentId: data.reportAssignmentId,
          });
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async updateNote({ commit, dispatch }, data) {
    return await api
      .post("/reportAssignments/updateNote", {
        ...data,
      })
      .then((response) => {
        if (response.success) {
          commit("UPDATE_NOTE", response.data);
          dispatch("loadReportAssignment", {
            reportAssignmentId: data.reportAssignmentId,
          });
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async deleteNote({ commit, dispatch }, data) {
    return await api
      .postQuery("/reportAssignments/deleteNote", data)
      .then((response) => {
        if (response.success) {
          commit("DELETE_NOTE", data.noteId);
          dispatch("loadReportAssignment", {
            reportAssignmentId: data.reportAssignmentId,
          });
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return false;
      });
  },
  async submitReportAssignment({ commit }, data) {
    return await api
      .postQuery("/reportAssignments/submitReportAssignment", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_REPORT_ASSIGNMENT", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async reviewReportAssignment({ commit, dispatch, rootState }, data) {
    let providerId = rootState.sharedModule.providerId;
    if (!providerId) {
      providerId = await dispatch("sharedModule/getProviderId", null, {
        root: true,
      });
    }
    let reviewerEmail = rootState.securityModule.userProfile?.email;

    return await api
      .postQuery("/reportAssignments/reviewReportAssignment", {
        reportAssignmentId: data.reportAssignmentId,
        providerId: providerId,
        isApproved: data.isApproved,
        comments: data.comments,
        sendEmail: data.sendEmail,
        reviewerEmail: reviewerEmail,
      })
      .then((response) => {
        if (response.success) {
          commit("UPDATE_REPORT_ASSIGNMENT", response.data);
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return false;
      });
  },
  async cancelReportAssignment({ commit }, data) {
    return await api
      .postQuery("/reportAssignments/cancelReportAssignment", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_REPORT_ASSIGNMENT", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async uploadReport({ commit, dispatch }, data) {
    return await api
      .uploadFile(
        "/reportAssignments/uploadReport",
        { reportAssignmentId: data.reportAssignmentId },
        data
      )
      .then((response) => {
        if (response.success) {
          commit("SET_SELECTED_REPORT_ASSIGNMENT", response.data);
          dispatch("loadReportAssignment", {
            reportAssignmentId: data.reportAssignmentId,
          });
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return false;
      });
  },
  async generateReportAssignments({ state, commit }) {
    let path;
    if (state.programView === "SEIT") {
      path = "/reportAssignments/generateSEITReportAssignments";
    } else if (state.programView === "EIO") {
      path = "/reportAssignments/generateEIReportAssignments";
    }
    return await api
      .post(path)
      .then((response) => {
        if (response.success) {
          commit("SET_REPORT_ASSIGNMENTS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async refreshReportAssignments({ state, commit }) {
    let path;
    if (state.programView === "SEIT") {
      path = "/reportAssignments/refreshSEITReportAssignments";
    } else if (state.programView === "EIO") {
      path = "/reportAssignments/refreshEIReportAssignments";
    }
    return await api
      .post(path)
      .then((response) => {
        if (response.success) {
          commit("SET_REPORT_ASSIGNMENTS", response.data);
        }

        return response.success;
      })
      .catch((err) => {
        return false;
      });
  },
  async getCurrentMandatesForClient({}, data) {
    return await api
      .getQuery("/client/getCurrentMandatesForClient", data)
      .then((response) => {
        if (response.success) {
          return response.data.map((item) => new Mandate(item));
        }

        return [];
      })
      .catch((error) => {
        return false;
      });
  },
  async getAssignmentsForClient({}, data) {
    return await api
      .getQuery("/client/getAssignmentsForClient", data)
      .then((response) => {
        if (response.success) {
          return response.data.map((item) => new Assignment(item));
        }

        return [];
      })
      .catch((error) => {
        return false;
      });
  },
  async getReportAssignmentFiles({}, data) {
    return await api
      .getQuery("/reportAssignments/getAcceptedReportAssignmentFiles", data)
      .then((response) => {
        if (response.success) {
          return response.data.map(
            (item) => new ReportAssignmentFileDetails(item)
          );
        }

        return [];
      })
      .catch((error) => {
        return false;
      });
  },
  async submitQuarterlyReports({ dispatch, rootState, rootGetters }, data) {
    return await api
      .post("/reportAssignments/submitQuarterlyReports", {
        ...data,
        userName: rootGetters["securityModule/fullName"],
        userEmail: rootState.securityModule.userProfile?.email,
      })
      .then((response) => {
        if (response.success) {
          dispatch("getSEITReportAssignments");
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return false;
      });
  },
  async getReportAssignmentRoster() {
    return await api
      .get("/reportAssignments/getReportAssignmentRoster")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return false;
      });
  },
  setFilters({ commit }, data) {
    commit("SET_FILTERS", data);
  },
  clearFilters({ commit }) {
    commit("CLEAR_FILTERS");
  },
  toggleProgramView({ commit }, data) {
    commit("TOGGLE_PROGRAM_VIEW", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
