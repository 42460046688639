<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">
        Choose Template
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2">
        Create Questions
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" step="3">Define Stubs</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 4" step="4"
        >Compose Narrative</v-stepper-step
      >

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 5" step="5">
        Review and Activate
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <validation-observer v-slot="{ invalid }">
          <v-card flat color="white" class="mb-6" min-height="250">
            <template-name-step
              @setSelectedTemplate="onSelectedTemplate"
            ></template-name-step
          ></v-card>

          <v-btn
            :dark="!invalid"
            color="primary"
            :disabled="invalid"
            @click="goToQuestionsStep"
            >Continue</v-btn
          >
          <v-btn text @click="$router.push({ name: 'Home' })">Cancel</v-btn>
        </validation-observer>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card flat color="white" min-height="250"
          ><questions-step></questions-step
        ></v-card>

        <v-btn dark color="primary" @click="e1 = 3">Continue</v-btn>
        <v-btn text @click="e1 = 1">Back</v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <validation-observer v-slot="{ invalid }">
          <v-card flat color="white" class="mb-6" min-height="250">
            <stubs-step></stubs-step>
          </v-card>

          <v-btn
            :dark="!invalid"
            color="primary"
            :disabled="invalid"
            @click="e1 = 4"
            >Continue</v-btn
          >
          <v-btn text @click="e1 = 2">Back</v-btn>
        </validation-observer>
      </v-stepper-content>

      <v-stepper-content step="4">
        <v-card flat color="white" class="mb-6" min-height="250"
          ><narrative-step></narrative-step
        ></v-card>

        <v-btn dark color="primary" @click="e1 = 5">Continue</v-btn>
        <v-btn text @click="e1 = 3">Back</v-btn>
      </v-stepper-content>

      <v-stepper-content step="5">
        <v-card flat color="white" class="mb-6" min-height="150"
          ><activate-step @activateChange="onActivateChange"></activate-step
        ></v-card>

        <v-btn dark color="primary" @click="save">Save</v-btn>
        <v-btn text @click="e1 = 4">Back</v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import TemplateNameStep from "./template-name-step";
import QuestionsStep from "./questions-step";
import StubsStep from "./stubs-step";
import NarrativeStep from "./narrative-step";
import ActivateStep from "./activate-step";
export default {
  components: {
    TemplateNameStep,
    QuestionsStep,
    StubsStep,
    NarrativeStep,
    ActivateStep,
  },
  data() {
    return {
      e1: 1,
      isNewTemplate: true,
      selectedTemplate: {},
      activate: true,
    };
  },
  computed: {
    selectedTemplateId() {
      return this.$store.state.templatesModule.selectedTemplate?.templateId;
    },
  },
  methods: {
    onSelectedTemplate: function (value) {
      this.isNewTemplate = value.isNewTemplate;
      this.selectedTemplate = value.template;
    },
    onActivateChange: function (value) {
      this.activate = value;
    },
    goToQuestionsStep: async function () {
      var message = "";
      if (this.isNewTemplate) {
        message = await this.$store.dispatch("templatesModule/addTemplate", {
          ...this.selectedTemplate,
        });
      } else {
        message = await this.$store.dispatch("templatesModule/updateTemplate", {
          ...this.selectedTemplate,
        });
      }

      if (message) {
        this.$store.commit("uxModule/setSnackbarMsg", message);
        this.$store.commit("uxModule/setShowSnackbar", true);
        return;
      }

      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch("templatesModule/loadTemplate", {
        templateId: this.selectedTemplateId,
      });
      this.$store.commit("uxModule/setShowLoader", false);

      this.e1 = 2;
    },
    save: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      var message = await this.$store.dispatch(
        "templatesModule/updateTemplate",
        {
          ...this.selectedTemplate,
          ...{ isActive: this.activate },
        }
      );
      this.$store.commit("uxModule/setShowLoader", false);

      if (message) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Error changing template activation status"
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
        return;
      }

      message = this.activate ? "Template Activated!" : "Template Deactivated";

      this.$store.commit("uxModule/setSnackbarMsg", message);
      this.$store.commit("uxModule/setShowSnackbar", true);

      this.$router.push({ name: "Home" });
    },
  },
};
</script>
