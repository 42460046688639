<template>
  <div>
    <component
      v-bind:is="field.componentName"
      :field="field"
      :value="field.value"
      :readonly="readonly"
      :outlined="field.outlined"
      @fieldChange="$emit('fieldChange', $event)"
      :validations="validations"
    ></component>
  </div>
</template>

<script>
import TextField from "./text-field";
import TextArea from "./text-area";
import Checkbox from "./checkbox-field";
import DatePicker from "./date-picker-field";
import TimeInputField from "./time-input-field";
import StandardSelect from "./standard-select";
import MultipleSelect from "./multiple-select";
import RadioGroup from "./radio-group";
import PhoneNumberField from "./phone-number-field";
import ZipCodeField from "./zip-code-field";
import Autocomplete from "./autocomplete";
import NumericField from "./numeric-field";
import TextEditorWithTable from "./text-editor-with-table.vue";
import TextEditor from "./text-editor";
import GoalsSelect from "./goals-select";
import LongTermGoalsSelect from "./long-term-goals-select"
import CognitiveGoalsSelect from "./cognitive-goals-select";
import SimpleTextEditor from "./simple-text-editor";
import MonthPicker from "./month-picker-field.vue";
import Signature from "./signature-field.vue";
import ChipSelect from "./chip-select.vue";
import MultipleChipSelect from "./multiple-chip-select.vue";
export default {
  name: "form-field",
  props: {
    field: Object,
    readonly: Boolean,
    validations: Object,
  },

  components: {
    "text-field": TextField,
    "text-area": TextArea,
    "checkbox": Checkbox,
    "date-picker": DatePicker,
    "time-input": TimeInputField,
    "standard-select": StandardSelect,
    "multiple-select": MultipleSelect,
    "radio-group": RadioGroup,
    "phone-number-field": PhoneNumberField,
    "zip-code-field": ZipCodeField,
    "autocomplete": Autocomplete,
    "numeric-field": NumericField,
    "text-editor-with-table": TextEditorWithTable,
    "text-editor": TextEditor,
    "goals-select": GoalsSelect,
    "long-term-goals-select": LongTermGoalsSelect,
    "cognitive-goals-select": CognitiveGoalsSelect,
    "simple-text-editor": SimpleTextEditor,
    "month-picker": MonthPicker,
    "signature": Signature,
    "chip-select": ChipSelect,
    "multiple-chip-select": MultipleChipSelect
  },
};
</script>
