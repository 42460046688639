<template>
  <v-data-table
    :headers="headers"
    :items="questions"
    v-sortable-data-table
    @sorted="saveOrder"
    item-key="questionId"
    sort-by="sequence"
    :items-per-page="-1"
    hide-default-footer
    dense
    class="elevation-1 primary--text"
    :key="questions.length"
  >
    <template v-slot:[`item.label`]="{ item }">
      <span v-sanitize-html="item.label"></span>
    </template>
    <template v-slot:[`item.componentName`]="{ item }">
      <v-chip color="primary" x-small label>{{ item.componentName }}</v-chip>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <question-modal :question="item" :familyId="familyId"></question-modal>
      <v-icon small @click="deleteQuestion(item)">mdi-delete</v-icon>
      <confirm-modal ref="confirm"></confirm-modal>
      <clone-question-action
        :questionId="item.questionId"
        :familyId="familyId"
      ></clone-question-action>
    </template>
  </v-data-table>
</template>
<script>
import QuestionModal from "./question-modal.vue";
import ConfirmModal from "../Shared/confirm-modal.vue";
import CloneQuestionAction from "./clone-question-action.vue";
import Sortable from "sortablejs";
export default {
  props: ["familyId"],
  components: {
    QuestionModal,
    ConfirmModal,
    CloneQuestionAction,
  },
  data() {
    return {
      headers: [
        { text: "Sequence", value: "sequence" },
        { text: "Question Text", value: "label" },
        { text: "Component Type", value: "componentName" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    questions: function () {
      if (this.familyId) {
        return this.$store.getters["templatesModule/getQuestionsByFamily"](
          this.familyId
        );
      }
      return [];
    },
  },
  methods: {
    deleteQuestion: async function (item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this question?"
        )
      ) {
        this.$store.commit("uxModule/setShowLoader", true);
        var message = await this.$store.dispatch(
          "templatesModule/deleteQuestion",
          {
            questionId: item.questionId,
          }
        );
        this.$store.commit("uxModule/setShowLoader", false);

        if (message) {
          this.$store.commit("uxModule/setSnackbarMsg", message);
          this.$store.commit("uxModule/setShowSnackbar", true);
        }
      }
    },
    async saveOrder(event) {
      this.$store.commit("uxModule/setShowLoader", true);
      const movedItem = this.questions.splice(event.oldIndex, 1)[0];
      this.questions.splice(event.newIndex, 0, movedItem);
      await this.$store.dispatch("templatesModule/saveQuestions", {
        questions: this.questions.map((item, index) => {
          return { ...item, sequence: index + 1 };
        }),
      });
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
};
</script>
