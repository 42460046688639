import ReportType from "./ReportType";
import Family from "./Family";
import NarrativeBlock from "./NarrativeBlock";

export default class Template {
  templateId?: number;
  name?: string | null;
  description?: string | null;
  programId?: number | null;
  documentFormat?: string | null;
  isActive?: boolean;
  includeLogo?: boolean;
  reportTypes?: ReportType[] | null;
  families?: Family[] | null;
  narrativeBlocks?: NarrativeBlock[] | null;

  public constructor(params: Template = {} as Template) {
    let {
      templateId = 0,
      name = "",
      description = "",
      programId = 0,
      documentFormat = "",
      isActive = false,
      includeLogo = false,
      reportTypes = [],
      families = [],
      narrativeBlocks = [],
    } = params;

    this.templateId = templateId;
    this.name = name;
    this.description = description;
    this.programId = programId;
    this.documentFormat = documentFormat;
    this.isActive = isActive;
    this.includeLogo = includeLogo;
    this.reportTypes =
      reportTypes && reportTypes.map((item) => new ReportType(item));
    this.families = families && families.map((item) => new Family(item));
    this.narrativeBlocks =
      narrativeBlocks &&
      narrativeBlocks.map((item) => new NarrativeBlock(item));
  }
}
