<template>
  <validation-observer v-slot="{ handleSubmit }" @submit.prevent>
    <v-card-title
      class="justify-center"
      style="height: 10%; word-break: break-word"
      >{{ family.label }}</v-card-title
    >
    <v-card-text style="height: 80%; overflow: auto">
      <question
        v-for="question in renderedQuestions"
        :key="question.questionId"
        :question="question"
        class="mx-4"
      >
      </question>
    </v-card-text>
    <v-card-actions style="height: 10%">
      <v-btn color="primary" text rounded small @click="back"
        ><icon icon="arrow-left" small class="mr-2"></icon>Back</v-btn
      >
      <dashboard-action></dashboard-action>
      <v-spacer></v-spacer>
      <span class="text-caption text--secondary"
        >Note: Responses are saved automatically.</span
      >
      <v-spacer></v-spacer>
      <v-btn color="primary" dark rounded @click="handleSubmit(next)"
        ><icon icon="arrow-right" class="mr-2"></icon>Next</v-btn
      >
    </v-card-actions>
  </validation-observer>
</template>
<script>
import Question from "./question";
import DashboardAction from "./dashboard-action.vue";
export default {
  props: ["family"],
  components: {
    Question,
    DashboardAction,
  },
  computed: {
    renderedQuestions: function () {
      if (this.family.familyId) {
        return this.$store.getters["notesModule/getRenderedQuestionsByFamily"](
          this.family.familyId
        );
      }
      return [];
    },
  },
  methods: {
    back: function () {
      this.$emit("goBack");
    },
    next: function () {
      this.$emit("goNext");
    },
  },
};
</script>
